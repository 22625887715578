(function(document, window, $) {
    'use strict';

    var Site = window.Site;

    $(document).ready(function($) {
        Site.run();


        $( document ).ajaxError(function( event, jqxhr, settings, thrownError ) {

            if (jqxhr.status == 401 ){//unauthorised
                //force a refresh and then will redirect to login
                location.reload();
            }
        });

    });


    window.addLoading = function(target) {
        $(target).html('<div class="height-10 text-center"><div class="loader loader-default"></div></div>');
    };


    window.getSelectedItems = function(target) {
        var $selectable = $(target+' [data-selectable]');
        var $selected = $selectable.asSelectable('getSelected');
        return $selected;
    };

    window.getSelectedItemsValues = function(target) {
        var items = getSelectedItems(target);
        var ret = [];
        $(items).each(function(){
            ret.push($(this).val());
        })

        return ret;
    }

    window.clear_form_elements = function(ele) {



        $(ele).find(':input').each(function() {
            switch(this.type) {
                case 'password':
                case 'select-multiple':
                case 'select-one':
                case 'text':
                case 'textarea':
                    $(this).val('');
                    break;
                case 'checkbox':
                case 'radio':
                    this.checked = false;
            }

            $(this).trigger('change');
        });


    };

    window.initSlidePanel = function($target){
        var target = "#filter-result";
        $('[data-type="selectedcount"]').each(function(){
            //var target = $(this).attr('data-target');
            var self = this;

            $(target).on('selected:changed', function(){
                $(self).text(getSelectedItemsValues(target).length);
                var mytarget = $(self).attr('data-target');
                $(mytarget).val(getSelectedItemsValues(target));
            });
        });

        $(target).trigger('selected:changed');


        $('[data-type="filteredcount"]').each(function(){
            var self = this;
            $(target).on('filter:done', function(){
                $(self).text($(target).find('.filtercount').first().text());
            });
        });

        $(target).trigger('filter:done');

        window.initOnSelectable();


    };

    window.removeTooltips = function(){
        $('.tooltip').remove();
    };

    window.showSlidePanel = function (url){
        $.slidePanel.show({
            url: url,
            settings: {
                cache: false
            }
        },  window.PanelOptions);
    }

    window.initOnSelectable = function() {
        $('[data-onselectable]').each(function () {
            $(this).slideUp(0);
            $.components._components.selectable.init();

            var starget ='#'+$(this).attr('data-onselectable');
            var $selectable = $(starget);

            var self = this;
            $selectable.on('asSelectable::change', function (e, api, checked) {

                $(self).postData = getSelectedItemsValues(starget);

                if (checked) {
                    $(self).slideDown();
                } else {
                    $(self).slideUp();
                }

            });
        });
    }

 var fullscreenurl = '';

   window.initPreview = function(url){

        var $fullscreener = $('#fullscreener');


       fullscreenurl = url;

       var $iframediv = $('#iframediv');

       if ($fullscreener.hasClass('hidden')) {
           $fullscreener.removeClass('hidden').fadeOut(0).fadeIn(500);
       }

        if (! $fullscreener.hasClass('initiated')) {
            $fullscreener.addClass('initiated');


            $('.fullscreenclose').on('click', function () {
                $iframediv.html('');
                $fullscreener.addClass('hidden');
                window.removeTooltips();
            });

            $('.btn-iresize').on('click', function () {
                $('.btn-iresize').removeClass('active');
                $(this).addClass('active');
                $('iframe').animate({'max-width': $(this).data('iresize')}, 200);
            });

            $('#fullscreener .language-switch').on('click', function(e){
                e.preventDefault();
                window.loadInPreview(fullscreenurl+'?lang='+$(this).data('langswitch'));
            })

        }else{
            //  $('.btn-iresize.active').click();
        }
       $('#fullscreener .language-switch.active').click();
        $('.btn-iresize').first().click();


    }

    window.loadInPreview = function (url){
        var $iframediv = $('#iframediv');

        if ($iframediv.find('iframe').length){
            var iframe = $iframediv.find('iframe').first()
            iframe.attr('src', url);

        }else {
            var iframe = $('<iframe>', {
                'src': url
            });
            $iframediv.append(iframe);

            iframe.load(function(){
                 $(this).fadeIn(200);
            });
        }

        iframe.fadeOut(0);

    }


})(document, window, jQuery);

(function(document, window, $) {
    'use strict';

    var Site = window.Site;

    $(document).ready(function($) {
        Site.run();

        var k=0;
        $(document).on('mouseover', '[data-has=popover]', function (e) {
            var $e = $(this);
            var id = $(this).data('id');
            k++;

            var div_id =  "tmp-id-" + $.now();
            var content = '<div id="'+ div_id +'" class="popover-wrapper"><div class="height-10 text-center"><div class="loader loader-default"></div></div></div>';

            popupDetails($(this).attr('data-pophref'), div_id, $e);

            $e.popover({
                    "placement": "bottom auto",
                    "trigger": "manual",
                    "html": true,
                    //"title": 'this is a title test '+k,
                    "content": content
                }).popover('show');
        });

        $(document).on('mouseout','[data-has=popover]', function(e){
            var target = $(this).attr('aria-describedby');
            $('#'+target).popover('destroy');
        });

        //
        //$(document).on('mouseout', '[data-has=popover]', function (e) {
        //    $(this).popover('hide');
        //});


        window.popupDetails = function(link, div_id, $e){

            if (link!=undefined)
            $.ajax({
                url: link,
                success: function(response){
                    $('#'+div_id).html(response);
                  //  $e.popover('show');
                }
            });
        }

    })

})(document, window, jQuery);

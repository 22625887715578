(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();




      Highcharts.theme = {
          colors: ["#77d6e1", "#a58add", "#f96868", "#46be8a", "#aaeeee", "#ff0066", "#eeaaee",
              "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],

          yAxis: {
              gridLineColor: '#e4eaec',
              gridLineDashStyle: 'dash'
          },
         /* chart: {
              backgroundColor: {
                  linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                  stops: [
                      [0, '#2a2a2b'],
                      [1, '#3e3e40']
                  ]
              },

              plotBorderColor: '#606063'
          },
          title: {
              style: {
                  color: '#E0E0E3',
                  textTransform: 'uppercase',
                  fontSize: '20px'
              }
          },
          subtitle: {
              style: {
                  color: '#E0E0E3',
                  textTransform: 'uppercase'
              }
          },
        */

        /*
          yAxis: {
              gridLineColor: '#707073',
              labels: {
                  style: {
                      color: '#E0E0E3'
                  }
              },
              lineColor: '#707073',
              minorGridLineColor: '#505053',
              tickColor: '#707073',
              tickWidth: 1,
              title: {
                  style: {
                      color: '#A0A0A3'
                  }
              }
          },
          tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              style: {
                  color: '#F0F0F0'
              }
          },
          plotOptions: {
              series: {
                  dataLabels: {
                      color: '#B0B0B3'
                  },
                  marker: {
                      lineColor: '#333'
                  }
              },
              boxplot: {
                  fillColor: '#505053'
              },
              candlestick: {
                  lineColor: 'white'
              },
              errorbar: {
                  color: 'white'
              }
          },
          legend: {
              itemStyle: {
                  color: '#E0E0E3'
              },
              itemHoverStyle: {
                  color: '#FFF'
              },
              itemHiddenStyle: {
                  color: '#606063'
              }
          },
          credits: {
              style: {
                  color: '#666'
              }
          },
          labels: {
              style: {
                  color: '#707073'
              }
          },

          drilldown: {
              activeAxisLabelStyle: {
                  color: '#F0F0F3'
              },
              activeDataLabelStyle: {
                  color: '#F0F0F3'
              }
          },

          navigation: {
              buttonOptions: {
                  symbolStroke: '#DDDDDD',
                  theme: {
                      fill: '#505053'
                  }
              }
          },

          // scroll charts
          rangeSelector: {
              buttonTheme: {
                  fill: '#505053',
                  stroke: '#000000',
                  style: {
                      color: '#CCC'
                  },
                  states: {
                      hover: {
                          fill: '#707073',
                          stroke: '#000000',
                          style: {
                              color: 'white'
                          }
                      },
                      select: {
                          fill: '#000003',
                          stroke: '#000000',
                          style: {
                              color: 'white'
                          }
                      }
                  }
              },
              inputBoxBorderColor: '#505053',
              inputStyle: {
                  backgroundColor: '#333',
                  color: 'silver'
              },
              labelStyle: {
                  color: 'silver'
              }
          },

          navigator: {
              handles: {
                  backgroundColor: '#666',
                  borderColor: '#AAA'
              },
              outlineColor: '#CCC',
              maskFill: 'rgba(255,255,255,0.1)',
              series: {
                  color: '#7798BF',
                  lineColor: '#A6C7ED'
              },
              xAxis: {
                  gridLineColor: '#505053'
              }
          },

          scrollbar: {
              barBackgroundColor: '#808083',
              barBorderColor: '#808083',
              buttonArrowColor: '#CCC',
              buttonBackgroundColor: '#606063',
              buttonBorderColor: '#606063',
              rifleColor: '#FFF',
              trackBackgroundColor: '#404043',
              trackBorderColor: '#404043'
          },

          // special colors for some of the
          legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
          background2: '#505053',
          dataLabelsColor: '#B0B0B3',
          textColor: '#C0C0C0',
          contrastTextColor: '#F0F0F3',
          maskColor: 'rgba(255,255,255,0.3)'
          */
      };

// Apply the theme
      Highcharts.setOptions(Highcharts.theme);



      $('#chart').highcharts({
          chart: {
              type: 'area'
          },
          title: {
              text: ''
          },

          xAxis: {
              categories: ['1750', '1800', '1850', '1900', '1950', '1999', '2050'],
              tickmarkPlacement: 'on',
              title: {
                  enabled: false
              }
          },
          yAxis: {
              title: {
                  text: 'Billions'
              },
              labels: {
                  formatter: function () {
                      return this.value / 1000;
                  }
              }
          },
          tooltip: {
              shared: true,
              valueSuffix: ' millions'
          },
          plotOptions: {
              area: {
                  stacking: 'normal',
                 // lineColor: '#666666',
                  lineWidth: 1,
                  marker: {
                      lineWidth: 1,
                      lineColor: '#666666'
                  }
              }
          },
          series: [{
              name: 'Asia',
              data: [502, 635, 809, 947, 1402, 3634, 5268]
          }, {
              name: 'Africa',
              data: [106, 107, 111, 133, 221, 767, 1766]
          }, {
              name: 'Europe',
              data: [163, 203, 276, 408, 547, 729, 628]
          }, {
              name: 'America',
              data: [18, 31, 54, 156, 339, 818, 1201]
          }, {
              name: 'Oceania',
              data: [2, 2, 2, 6, 13, 30, 46]
          }]
      });




  });



})(document, window, jQuery);

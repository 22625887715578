(function(window, document, $) {
    'use strict';

    var pluginName = 'selfLoadable';

    var Plugin = $[pluginName] = function(element, options) {
        this.element = element;
        this.$element = $(element);

        this.options = $.extend({}, Plugin.defaults, options, this.$element.data());

        this.init();
    };

    Plugin.defaults = {
        url :'',
        onShow: function() {},
        onHide: function() {}
    };

    Plugin.prototype = {
        constructor: Plugin,
        init: function () {

            var self = this;
            var $xhr = $.post(this.options.url)
                    .done(function(data){
                       self.$element.html(data);
                        $.components._components.selectable.init();
                    })
                    .fail(function(){

                    })
                    .always(function(){


                    })
                ;
        }
    };


    $.fn[pluginName] = function(options) {
        if (typeof options === 'string') {
            var method = options;
            var method_arguments = Array.prototype.slice.call(arguments, 1);

            if (/^\_/.test(method)) {
                return false;
            } else if ((/^(get)$/.test(method))) {
                var api = this.first().data(pluginName);
                if (api && typeof api[method] === 'function') {
                    return api[method].apply(api, method_arguments);
                }
            } else {
                return this.each(function() {
                    var api = $.data(this, pluginName);
                    if (api && typeof api[method] === 'function') {
                        api[method].apply(api, method_arguments);
                    }
                });
            }
        } else {
            return this.each(function() {
                if (!$.data(this, pluginName)) {
                    $.data(this, pluginName, new Plugin(this, options));
                }
            });
        }
    };

})(window, document, jQuery);
(function(document, window, $) {
    'use strict';

    var Site = window.Site;

    $(document).ready(function($) {
        Site.run();

        $(document).on('change','[data-type="template_picker_select"]', function(e){
            var $target = $(this).closest('[data-type="template_picker"]');
            var url = $target.data('url');
            var id = $(this).val();

            $.post(url, {id: id})
                .done(function(data){
                    if (data.dinamic != undefined)
                    $target.find('.template_dinamic').html(data.dinamic);
                    window.initTinymce();
                    window.initLangSwitch();
                })
                .always(function() { });
        });

        $('[data-type="template_picker_select"]').trigger('change');
    });

})(document, window, jQuery);

(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();


      var alertify_defaults= {
          type: "alert",
          delay: 5000,
          theme: 'bootstrap'
      };

      alertify.theme(alertify_defaults.theme);

      $('.server-alert').each(function(){
          var message = $(this).html();
          inMessageError(message);
      })

  });

var alertify_defaults= {
    type: "alert",
        delay: 5000,
        theme: 'bootstrap'
};

  window.inConfirm = function(message, callBackOk, callBackNot) {

      alertify.theme(alertify_defaults.theme);
      // confirm dialog
      alertify.confirm( message, function () {
          // user clicked "ok"
      }, function() {
          // user clicked "cancel"
      });
    };

var message_defaults = {
    "type":"info",
    "title":"",
   //"positionClass": "toast-top-full-width",
    "showMethod": "slideDown",
    "hideMethod": "slideUp",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
};

window.inMessageError = function(message) {
    inMessage(message, {type:'error'});
};

 window.inMessage = function(message, options){
     var options = $.extend(true, {}, message_defaults, options);

     var title = options.title;
     var type = options.type || "info";
    //var type = "error";

     switch (type) {
         case "success":
             toastr.success(message, title, options);
             break;
         case "warning":
             toastr.warning(message, title, options);
             break;
         case "error":
             toastr.error(message, title, options);
             break;
         case "info":
             toastr.info(message, title, options);
             break;
         default:
             toastr.info(message, title, options);
     }
 }

window.inErrorMessage =  function(message, title){
    inMessage(message, title, {type : "error"});
}

})(document, window, jQuery);

function initMatching(){

    initMatchingButtons();
}

function initMatchingButtons(){


    $('#saveMatchingAction').click(function(){

        var target = $(this).attr('data-targetform');
        var $target = $(target);
        //   console.log(target);
        $(window).trigger('beforeajaxsaving');
        var l = Ladda.create(this);
        l.start();

        $.post($target.attr('action'), $target.serialize())
            .done(function(data){
                if (data.status){
                    $('#filter').trigger('filter:refresh');
                    $('.slidePanel-close').trigger('click');
                }

            })
            .always(function() { l.stop(); });



    });

}
(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();

      //$(document).on('click','[data-type="models"]', function(e) {
      //
      //    var url = $(this).data('url');
      //
      //    bootbox.dialog({
      //        title: "That html",
      //        message: ' <div id="modelsDiv" data-url="'+ url +'"/>'
      //    });
      //
      //    $('#modelsDiv').selfLoadable();
      //});


      $(document).on('change', '[data-closesttoggle]', function(e){
            var target= $(this).data('closesttoggle');
            var $root = $(this).closest('.closesttoggle-root');
            $root.find('.closesttoggle').fadeOut(0);

            var $target = $root.find(target).first();
            if ($(this).is(':checked')) {
                $target.slideDown();
            }else{
                $target.slideUp();
            }
      });


      $(document).on('click','[data-deletetarget]', function(e) {
            var target = $(this).data("deletetarget");
            $(target).remove();
      });

      $(document).on('click','[data-popup]', function(e) {

          var url = $(this).data('href');

        window.open(url,"presentation", "fullscreen=yes,scrollbars=yes");
      });



      $(document).on('change','[data-type="modelselect"]', function(e) {
          var $target = $(this).parent().find('.translatable');
          var content = $(this).parent().find('[data-content="'+$(this).val()+'"]').html();

          if ($target.hasClass('editor')){
                tinyMCE.get($target.attr('id')).setContent(content);
          }else {
              $target.val(content);
          }

      });


      $(document).on('click','[data-type="autotranslate"]', function(e){
            var $target = $(this).parent().find('.translatable');

          $(window).trigger('beforeajaxsaving');
          var url = $(this).data('url');
          var to = $(this).parent().data('lang');
          var $fromLangTarget = $(this).closest('.form-group').find('.translatable').first();
          var from = $fromLangTarget.parent().data('lang');
          var what = $fromLangTarget.val();

          $.post(url, { 'from': from, 'to': to, 'what':what})
              .done(function(data){
                  if (data.translation !=undefined){
                      if ($target.hasClass('editor')){
                          tinyMCE.get($target.attr('id')).setContent(data.translation);
                      }else {
                          $target.val(data.translation);
                      }
                  }
              })

      });

      $(document).on('click','[data-filterable]', function(e){

          var targetinput = $(this).data('filterable');
          $('#filter [name="'+targetinput+'"]').first().val($(this).data('filtervalue')).trigger('change');
          $('[data-filterable="'+targetinput+'"').removeClass('active');
          $(this).addClass('active');
      });


      $(document).on('click','[data-type="previewfullscreen"]', function(e){
          e.preventDefault();


          var target = $(this).attr('data-form');
          var $target = $(target);

          var l = Ladda.create(this);
          l.start();
          $(window).trigger('beforeajaxsaving');
          $.post($(this).data('href'), $target.serialize())
              .done(function(data){
                  if (data.url != undefined){
                        initPreview(data.url);
                  }

              })
              .always(function() { l.stop(); });

      });

      $(document).on('click','[data-previewfullscreen]', function(e){
          e.preventDefault();

          initPreview($(this).data('previewfullscreen'));

      });


      $(document).on('click','[data-type="opinion"]', function(e){
          var isactive = ! $(this).hasClass('btn-outline');
          var value = $(this).attr('data-value');

          $(this).parent().find('.btn').addClass('btn-outline');

          if (!isactive)
            $(this).removeClass('btn-outline');
          else{
              value = 0;
          }

          var url = $(this).parent().attr('data-url');
          if (url){
              $.post(url, {'like': value});
          }
      });




      window.initOnSelectable();

      //we hide the multi delete
      $('[data-type="deletemulti"]').each(function(){
          $(this).slideUp(0);
          var target = $(this).attr('data-target');
            var self = this;

          $(target).on('selected:on', function(){
              $(self).slideDown();
          });

          $(target).on('selected:off', function(){
              $(self).slideUp();
          });

      });

      //delete single
      $(document).on('click','[data-type="deletesingle"]', function(e){
          e.preventDefault();

          var url = $(this).attr('data-href');

          var that = $(this);

          //alertify.confirm( "Delete ?", function () {
          //    $(that).closest('.model-item').fadeOut();
          //    sendPost('#', $(that).closest('.model-item'), '', unfadeElement);
          //});




          bootbox.confirm("Are you sure you want to delete?", function(result) {
              if (result) {

                  var mode = $(that).attr('data-mode');
                  if (typeof mode !== 'undefined'){
                      if (mode == 'panel') {
                          $('.slidePanel-close').trigger('click');
                      }
                  }

                  $(this).addClass('disabled');

                  if (url.indexOf('#') == 0){ //we just want to submit the form with the method delete
                    $(url+' input[name=_method]').val('DELETE');
                    $(url).submit();
                  }else {
                      $.post(url, {'_method': 'delete'})
                          .done(function (data) {
                              var eventAfter = $(that).attr("data-eventafter");
                              if (typeof eventAfter !== 'undefined')  $('body').trigger(eventAfter);

                              var callbackAfter = $(that).attr("data-callbackafter");
                              var fn = window[callbackAfter];
                              console.log(typeof fn);
                              if (typeof fn === "function") {
                                  fn();
                              }
                          })
                          .fail(function () {
                              inMessage('Bla bla bla', {message: 'Bla bla erased', type: 'error'});
                          })
                          .always(function () {

                          });
                  }
              }
          });

      });


    //delete multi
      $(document).on('click','[data-type="deletemulti"]', function(e){
          e.preventDefault();

          var target = $(this).attr('data-target');
          //var url = $(this).attr('data-url');
          var url = $(this).attr('data-href');

          var $selectedIds = getSelectedItemsValues(target);

          if ($selectedIds.length == 0){
              inErrorMessage('Select some items please');
              return;
          }


          var filterSelector = $(target).attr('data-filter');

          var filter = $(target).attr('data-filter');

          var self = this;

          bootbox.confirm("Are you sure you want to delete?", function(result) {
              if (result) {
                  $(this).addClass('disabled');
                  $.post(url, {'ids': $selectedIds, '_method': 'delete'})
                      .done(function (data) {

                          $(filter).trigger('filter:refresh');
                      })
                      .fail(function () {
                          inMessage('Bla bla bla', {message: 'Bla bla erased', type: 'error'});
                      })
                      .always(function () {
                          $(self).removeClass('disabled');
                      });
              }
          });

          /*
          bootbox.confirm("Are you sure?", function(result) {
              if (result) {
                  var filter = $(target).attr('data-filter');
                  $(filterSelector).trigger('filter:refresh');
              }

          });
            */

      });



      function unfadeElement(element){
          $(element).fadeIn();
      }


      function sendPost(url, callbackOption, callbackOk, callbackFail ){

          inMessage('Bla bla bla', {message: 'Bla bla erased', type: 'error'});
          if (typeof callbackOk === "function") {
              callbackOk(callbackOption);
          }
          if (typeof callbackFail === "function") {
              callbackFail(callbackOption);
          }
      }


      //prevent slidepanel to show up when clicking on a button inside a table tr
      $(document).on('click','tr .btn', function(e){
          e.stopPropagation();
      });

      //filter button



      $(document).on('click','[data-type="filter-cleaner"]', function(e){
          e.preventDefault();
          var target = $(this).closest('form');
          $(target).trigger('filter:nofiltering');
          clear_form_elements(target);
          $(target).trigger('filter:clear');
          $(target).trigger('filter:filtering');
          $(target).trigger('filter:refresh');

      });



      //
      //$(document).on('click','[data-type="filterbutton"]', function(e){
      //    e.preventDefault();
      //    var target = $(this).attr('data-href');
      //
      //    ($(target).hasClass('in')) ? $(this).removeClass('active') : $(this).addClass('active');
      //
      //    $(target).collapse('toggle');
      //});


      $(document).on('click','[data-type="saveform"]', function(e){

          e.preventDefault();

         var target = $(this).attr('data-href');
          var back = $(this).attr('data-back');
          if (back) {
              var input ='<input type="hidden" name="backurl" value="'+back+'">';
              $(input).appendTo(target);
          }
          $(target).submit();

      });


      $(document).on('click','[data-type="gotourl"]', function(e){

          e.preventDefault();

          var url = $(this).attr('href');
          window.location = url;

      });

      $(document).on('click','[data-type="filterbutton"]', function(e){
          e.preventDefault();
          var target = $(this).attr('data-href');

          ($(target).hasClass('in')) ? $(this).removeClass('active') : $(this).addClass('active');

          $(target).collapse('toggle');
      });

      $('[data-type="filterbutton"]').each(function(){
          var target = $(this).attr('data-href');
          var isFiltered = $(target).find('form').first().attr('data-filtered');


          if (parseInt(isFiltered)) {
              $(this).addClass('active');
              $(this).trigger('click');
          }
      });





  });



})(document, window, jQuery);

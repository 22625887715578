(function(document, window, $) {
    'use strict';

    var Site = window.Site;

    $(document).ready(function($) {
        Site.run();

        if (window.categories == undefined) return;

        var localcategories = [];

        for (var index in window.categories) {
            localcategories[window.categories[index].id]=window.categories[index];
        }



        window.categories = localcategories;

        $(document).on('change','[data-type="modifier"]', function(e){
            var parent = $(this).closest('[data-type="mainfieldwrapper"]');

            var value = $(this).val();



            parent.find('[data-type="modificable"]').hide();

            for (var index in categories[value].fields){

                parent.find('[data-field="'+ categories[value].fields[index] +'"]').show();
            }

        });

        $('[data-type="modifier"]').trigger('change');

        //$('[data-type="modifier"]').each(function(){
        //
        //    var parent = $(this).closest('[data-type="mainfieldwrapper"]');
        //
        //
        //
        //});



    });

})(document, window, jQuery);
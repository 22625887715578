(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();

    var filter = $('#filter');

    if (!filter.length) return;

    var $panel = $('#filter-result');
    var api = $panel.data('panel-api');
    var filtering = true;
    var filter_in_progress=false;

    var pageChange = false;

      $panel.sortableNav();

      $('#filter input, #filter select').change(function() {
          makeFilterRequest();

      });

      $('#filter').submit(function(event){
           event.preventDefault();
            makeFilterRequest();
      });

      $('#filter').on('filter:refresh', function( event ){
          makeFilterRequest();
      });


      $('#filter').on('filter:nofiltering', function(){

            filtering = false;
      });

      $('#filter').on('filter:filtering', function(){
          filtering = true;

      });


      function makeFilterRequest(){

          if (! filtering) return;
          api.load(function(done) {
              //reset the page to 1 if there is a filter change
              if (! pageChange)   $('input[name=page]').val(1);
              pageChange = false;

              var $panel = $(this);
              $panel.find('.panel-body .content').html('');

              var url = filter.attr('data-href');
             // console.log('makeFilterRequest');
              if (filter_in_progress) $xhr.abord();

              filter_in_progress=true;

              var $xhr = $.post(url, filter.serialize())
                  .done(function(data){
                      $panel.find('.panel-body').html(data);

                      //markSortable();
                      selectableInit();
                      $panel.sortableNav('reset');
                      $('#filter-result').trigger('filter:done');
                  })
                  .fail(function(){
                      $panel.find('.panel-body .content').html();
                  })
                  .always(function(){
                      done();
                      filter_in_progress = false;
                  })
              ;

             // $panel.find('.panel-body').html('');
          });
      }

      //trigger first load
      //$('#filter input[name=page]').trigger("change");
      makeFilterRequest();


      //Pagination
      $(document).on('click','#filter-result .pagination a', function(e){
          e.preventDefault();
          var href= $(this).attr('href');
          //for this next line will do the trick but is not suggested to be used anywhere else
          var page=href.split('page=')[1];
          pageChange = true;
          $('input[name=page]').val(page).trigger("change");

      });



      //$(document).on('click','#filter-result .sortablefield', function(e){
      //    e.preventDefault();
      //
      //    var sortActive = $('#filter input[name=order]').val();
      //    var sortDirection = $('#filter input[name=direction]').val();
      //
      //    var mysort = $(this).attr('data-field');
      //    var newdirection = 'asc';
      //    if (sortActive == mysort) newdirection = (sortDirection=='asc') ? 'desc' : 'asc';
      //
      //    $('#filter input[name=order]').val(mysort);
      //    $('#filter input[name=direction]').val(newdirection).trigger("change");
      //
      //
      //});




      function selectableInit(){
          $( document ).trigger( "enhance.tablesaw" );

          $.components._components.selectable.init();

          var $selectable = $('#filter-result [data-selectable]');

          $('#filter-result').trigger('selected:off');
          $('#filter-result').trigger('selected:changed');

          $selectable.on('asSelectable::change', function(e, api, checked) {

              $('#filter-result').trigger('selected:changed');


              if (checked) {
                  $('#filter-result').trigger('selected:on');
              } else {
                  $('#filter-result').trigger('selected:off');
              }

          });
      }

      //paging

      var paging = $('#filter input[name=limit]').val();
      $('#filter-result .paging [data-paging='+paging+']').addClass('active');

      $('#filter-result .paging a').on('click', function(){
          $('#filter-result .paging a.active').removeClass('active');
          $(this).addClass('active');
          $('input[name=limit]').val($(this).attr('data-paging')).trigger("change");
      })


  });


})(document, window, jQuery);

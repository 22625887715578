(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();

      window.initDatePicker = function(){

          $('[data-plugin=datepicker]').each(function () {
              $(this).datepicker({
                  format: "dd-mm-yyyy",
                  autoclose: true,
                  todayHighlight: true,
                  orientation: "bottom auto"
              });
          });
      }


      window.initTimePicker = function(){

          $('[data-plugin=clockpicker]').each(function () {
              $(this).clockpicker({
                      autoclose: true,
                      align: 'right'
                  }
              );
          });
      }

  });



})(document, window, jQuery);

(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();

      var $topSearch = $('#top-search');

      if ($topSearch.length) {

          var searchData = new Bloodhound({
              datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
              queryTokenizer: Bloodhound.tokenizers.whitespace,
              remote: {
                  url: $topSearch.attr('data-url')+'?q=%QUERY',
                  wildcard: '%QUERY'
              }
          });



         $topSearch.typeahead(null, {
              name: 'search',
              display: 'value',
              source: searchData,
             templates:{
                 suggestion: function(data){
                     var ret = '<div>';
                     if (data.html != undefined){
                         ret += data.html;
                     } else{
                         ret += data.value;
                     }
                     ret +='</div>';
                     return ret;
                 }
             }
          });


          $topSearch.bind('typeahead:select', function(ev, suggestion) {
              console.log('Selection: ');
              window.showSlidePanel(suggestion.slidepanel);
          });
      }

  });





})(document, window, jQuery);

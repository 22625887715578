(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();

      $(".select2-cities").each(function(){
          var that = this;

          var $element=  $(this).select2({
              ajax: {
                  url: $(that).attr('data-url'),
                  dataType: 'json',
                  delay: 250,
                  data: function (params) {
                      return {
                          q: params.term
                      };
                  },
                  processResults: function (data, params) {

                      return {
                          results: data.items

                      };
                  },
                  cache: true
              },
              width: "style",
              escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
              minimumInputLength: 1,
              templateResult: autoFormatResult, // omitted for brevity, see the source of this page
              templateSelection: locationFormatSelection // omitted for brevity, see the source of this page

          });


          var current = $(that).attr('data-current');


          if (current!=''){
              $.ajax($(that).attr('data-url'), {
                  data: {
                      id: current
                  },
                  dataType: "json"
              }).done(function(data) {
                  $.each(data.items, function( i, val){
                      var $option = new Option(val.text, val.id, true, true);
                      $element.append($option);

                  });
                  $element.trigger('change'); // notify JavaScript components of possible changes
              });

          }

        });


      $(".select2-zones").each(function(){
          var that = this;

          var $element=  $(this).select2({
              ajax: {
                  url: $(that).attr('data-url'),
                  dataType: 'json',
                  delay: 250,
                  data: function (params) {
                      return {
                          q: params.term,
                          cities_id: $('#cities-id').val()
                      };
                  },
                  processResults: function (data, params) {

                      return {
                          results: data.items

                      };
                  },
                  cache: true
              },
              width: "style",
              escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
              minimumInputLength: 0,
              templateResult: autoFormatResult, // omitted for brevity, see the source of this page
              templateSelection: locationFormatSelection // omitted for brevity, see the source of this page

          }).focus(function () { $(this).select2('open'); });


          var current = $(that).attr('data-current');


          if (current!=''){
              $.ajax($(that).attr('data-url'), {
                  data: {
                      id: current
                  },
                  dataType: "json"
              }).done(function(data) {
                  $.each(data.items, function( i, val){
                      var $option = new Option(val.text, val.id, true, true);
                      $element.append($option);

                  });
                  $element.trigger('change'); // notify JavaScript components of possible changes
              });

          }

      });




      $(".select2-ajax").each(function(){
          var that = this;

          var $element=  $(this).select2({
              ajax: {
                  url: $(that).attr('data-url'),
                  dataType: 'json',
                  delay: 250,
                  data: function (params) {
                      return {
                          q: params.term
                      };
                  },
                  processResults: function (data, params) {

                      return {
                          results: data.items

                      };
                  },
                  cache: true
              },
              width: "style",
              escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
              templateResult: autoFormatResult, // omitted for brevity, see the source of this page
              templateSelection: locationFormatSelection // omitted for brevity, see the source of this page

          });


          var current = $(that).attr('data-current');


          if (current!=''){
              $.ajax($(that).attr('data-url'), {
                  data: {
                      id: current
                  },
                  dataType: "json"
              }).done(function(data) {
                  $.each(data.items, function( i, val){
                      var $option = new Option(val.text, val.id, true, true);
                      $element.append($option);

                  });
                  $element.trigger('change'); // notify JavaScript components of possible changes
              });

          }

      });




  });


    window.autoFormatResult= function(item) {

        return item.text;
    }

    window.locationFormatSelection= function(item) {

        return item.text;
    }




})(document, window, jQuery);

(function(window, document, $) {
    'use strict';

    var pluginName = 'sortableNav';

    var Plugin = $[pluginName] = function(element, options) {
        this.element = element;
        this.$element = $(element);

        this.options = $.extend({}, Plugin.defaults, options, this.$element.data());
        //console.log(element + 'construct');
        this.init();
    };

    Plugin.defaults = {
        trigger: 'click', // click, hover
        listSelector: '.sortablefield',
        iconUp: 'ti-angle-up',
        iconDown: 'ti-angle-down',
        colorClass : 'text-success',
        form: '#filter',
        onShow: function() {},
        onHide: function() {}
    };

    Plugin.prototype = {
        constructor: Plugin,
        init: function () {

            this.$form = $(this.options.form);

            this.$sortActiveInput = this.$form.find('input[name=order]');
            this.$sortDirectionInput = this.$form.find('input[name=direction]');

            this.reset();

            var self = this;

           this.$element.on('click', this.options.listSelector, function() {

               var mysort = $(this).attr('data-field');
               if (self.sortActive == mysort) var newdirection = (self.sortDirection=='asc') ? 'desc' : 'asc';

               self.changed(mysort, newdirection);
           })


        },

        reset: function(){
            this.sortActive = this.$sortActiveInput.val();
            this.sortDirection = this.$sortDirectionInput.val();

            //we create the icon for active state
            var icon = (this.sortDirection=='asc') ? this.options.iconUp : this.options.iconDown;
            var iconwrapped = ' <i class="icon ' + this.options.colorClass + ' ' + icon + '"></i> ';

            //add the icon on the field
            var selector = this.options.listSelector+'[data-field="'+ this.sortActive+'"]';
            this.$element.find(selector).append(iconwrapped).addClass('active');

        },

        changed: function(fieldName, direction){
            this.$sortActiveInput.val(fieldName);
            this.$sortDirectionInput.val(direction);
            this.$form.trigger("filter:refresh")
        }


    };


    $.fn[pluginName] = function(options) {

        if (typeof options === 'string') {
            var method = options;
            var method_arguments = Array.prototype.slice.call(arguments, 1);

            if (/^\_/.test(method)) {
                return false;
            } else if ((/^(get)$/.test(method))) {
                var api = this.first().data(pluginName);
                if (api && typeof api[method] === 'function') {
                    return api[method].apply(api, method_arguments);
                }
            } else {
                return this.each(function() {
                    var api = $.data(this, pluginName);
                    if (api && typeof api[method] === 'function') {
                        api[method].apply(api, method_arguments);
                    }
                });
            }
        } else {

            return this.each(function() {
                if (!$.data(this, pluginName)) {
                    $.data(this, pluginName, new Plugin(this, options));
                }
            });
        }
    };

})(window, document, jQuery);
(function(document, window, $) {
    'use strict';

    var Site = window.Site, gridster=null;

    $(document).ready(function($) {
        Site.run();


       gridster = $(".gridster").gridster({
            widget_selector:'div',
            widget_base_dimensions: ['auto', 100],
           autogenerate_stylesheet: true,
            min_cols: 1,
            max_cols: 12,
            widget_margins: [15,15],
            resize: {
                enabled: true
            }
        }).data('gridster');
        $('.gridster').css({'width': $(window).width()});

    });


})(document, window, jQuery);
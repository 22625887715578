function initActivity(){
    initSelectize();
    initSelectizeAjax();
    initDatePicker();
    initTimePicker();

    initActivityButtons();

   // var finished = $('#activity-form #status:checked').val();



    $('#activity-form #status').change(function(){
        if($(this).is(':checked')){
            $('#finished-action').show();
        }else{
            $('#finished-action').hide();
        }
    });


    $('#activity-form #status').trigger('change');

    if ($('#activity-form input[name="id"]').val()== '' ){

    }

}

function initActivityButtons(){


    $('#saveAction').click(function(){

        var target = $(this).attr('data-targetform');
        var $target = $(target);
     //   console.log(target);

        var l = Ladda.create(this);
        l.start();

        $.post($target.attr('action'), $target.serialize())
            .done(function(data){
                if (data.status){
                    $('#filter').trigger('filter:refresh');
                    $('body').trigger('activity:added');
                    $('.slidePanel-close').trigger('click');
                }

            })
            .always(function() { l.stop(); });



    });

}
(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();



      /*
       * DROPZONE
       */

      window.initDropzone = function(target = '.mdropzone' ) {
          //   console.log('initDropzone '+target);
            if ($(target).length)
              $(target).each(addDropzoneEvent);

          function addDropzoneEvent() {

              var concat = '?';
              //TODO change the way we get-send token
              var field=$(this).attr('data-field');
              var customformat = $(this).attr('data-customformat');
              var url=$(this).attr('data-url')+concat+'component='+$(this).attr('data-component')+'&customformat='+customformat+'&field='+field+'&_token='+$('meta[name="csrf-token"]').attr('content');
              var id = $(this).attr('id');
              var preview = $(this).attr('data-preview');
              var mode = $(this).attr('data-mode');

              var customCall = $(this).attr('data-custom-call');
              var dz = $('#' + id).dropzone({
                  url: url,
                  autoProcessQueue: true,
                  uploadMultiple: true,
                  parallelUploads: 1,
                  maxFiles: 100,
                  addRemoveLinks: true,
                  dictDefaultMessage: '<i class="icon ti-upload"></i> DROP FILES HERE TO UPLOAD',
                  init: function () {

                      var mdropzone = this;

                      this.on("success", function (file, responseText) {
                          //for single image
                          if (mode == 'single') {
                              if (responseText['template'] == undefined) {
                                  if (preview != undefined)
                                      $('#' + preview).attr('src', responseText['filename']);
                                  $('#' + field).val(responseText['filename']);

                                  if (responseText['value'] != undefined)
                                      $('#' + field).val(responseText['value']);

                              }else {
                                  if (customCall != undefined) {
                                      window[customCall](responseText['filename'], responseText['template']);
                                  }else {
                                      if (responseText['template'] != undefined) {
                                          addSinglePreviewTemplate(preview, responseText['template']);
                                      }
                                  }
                              }


                          } else {
                              //for multiple images
                              if (customCall != undefined) {
                                  window[customCall](responseText['filename'], responseText['template']);
                              } else {
                                  // addMultiPreviewFile(preview, responseText['filename']);
                                  //$('#' + preview).append(responseText['template']);
                                  addMultiPreviewTemplate(preview, responseText['template']);
                              }
                          }

                          $(file.previewTemplate).remove();

                      });

                      this.on("addedfile", function (file) {
                          // console.log("Added file.");
                      });

                      this.on("removedfile", function (file) {
                          //console.log("removedfile file:");
                          //console.log(file);
                      });

                      this.on("processingfile", function (file) {
                          //mdropzone.options.url = "/some-other-url";
                          //console.log("Process");
                      });

                      this.on("error", function (file) {
                          inMessageError("Error uploading file");
                          $(file.previewTemplate).remove();
                      });


                  }

              });
          }
      }

      window.initDropzone();


  });

    window.addMultiPreviewTemplate = function(target, template){
        var $target = $('#'+target);
        $target.append(template);
        $target.sortable();
        window.initLangSwitch();
    };

    window.addSinglePreviewTemplate = function(target, template){
        var $target = $('#'+target);
        $target.html(template);
        $target.sortable();
        window.initLangSwitch();
    };

   // window.addMultiPreviewFile()

    Dropzone.autoDiscover = false;

})(document, window, jQuery);

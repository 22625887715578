(function(document, window, $) {
    'use strict';

    var Site = window.Site;

    $(document).ready(function($) {
        Site.run();

        $('#savemodal').click(function(){

            var target = $(this).attr('data-targetform');
            var $target = $(target);

            var modal = $(this).attr('data-targetmodal');

            var l = Ladda.create(this);
            l.start();

            $.post($target.attr('action'), $target.serialize())
                .done(function(data){

                    window.clear_form_elements(target);
                    $(modal).modal('hide');
                    $(document).trigger('savemodal:clear');
                  //  $(document).trigger(' selectizeajax:clear');
                    $(document).trigger('selectizeajax:addcontact',[{id:data.id,name:data.name}]);
                })
                .always(function() { l.stop(); });



        });

        $('#cancelmodal').click(function(){
            var target = $(this).attr('data-targetform');
            $(document).trigger('savemodal:clear');

        })


    });





})(document, window, jQuery);